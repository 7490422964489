/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { compact, groupBy, orderBy } from 'lodash'
import { styled, Grid, Divider, Icon } from '@mui/material'
import moment from 'moment'
import { TextButton } from '../components/styled/buttons'
import AgriAction from '../agri/actions/AgriAction'
import { formatMilliers } from '../../../utils/StringUtil'
import CmsAction from '../cms/actions/CmsAction'
import { getDate, getMiniDate, getMiniDateNoYear } from '../../../utils/DateUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { mainColor, screenColor, veryLightColor } from '../components/styled/theme'
import ContactAction from '../contact/actions/ContactAction'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import { getEvolValue } from '../../../utils/AgriUtils'
import { CHRONICLES_CONSTANTS } from '../agri/constants/AgriConstants'
import { PlayArrow } from '@mui/icons-material'
import LoadingCard from '../components/cards/LoadingCard'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../referencials/materiels/constants/MaterielConstants'
import AccordeonDesktop from '../components/AccordeonDesktop'
import { ReactComponent as PumpLogo } from '../../../ressources/static/svg/Pump.svg'
import { ReactComponent as CounterLogo } from '../../../ressources/static/svg/Counter.svg'
import useApplicationSetting from '../../../utils/customHooks/useApplicationSetting'

const GridItem = styled(Grid)({
    padding: '4px !important',
    textAlign: 'center',
    alignSelf: 'center',
})

const PointConsoDesktop = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [contactsLoaded, setContactsLoaded] = useState(false)
    const [realConso, setRealConso] = useState()
    const [year, setYear] = useState(new Date().getFullYear())

    const dispatch = useDispatch()

    const {
        variousMateriels,
        variousMatSituations,
        exploitation,
        exploitationVolumes,
    } = useSelector((store) => ({
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        exploitation: store.AgriReducer.exploitation,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
    }), shallowEqual)

    const idInstallation = useMemo(() => Number(props.match.params.id), [])
    const date = useMemo(() => moment().valueOf(), [])
    const linkPoint = exploitation.link_samplings.find((l) => l.idInstallation === idInstallation) || {}
    const { pumpsIds, countersIds } = (() => {
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === idInstallation && (!m.situationEndDate || m.situationEndDate > date) && (!m.situationDate || m.situationDate < date)) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_PUMP_COUNTER && pointPumps.find(({ idVarious }) => idVarious === m.siteCode) && (!m.situationEndDate || m.situationEndDate > moment(`1/01/${year}`, 'DD/MM/YYYY').valueOf()) && (!m.situationDate || m.situationDate < moment(`31/12/${year}`, 'DD/MM/YYYY').valueOf())) || []
        return { pumpsIds: pointPumps.map(({ idVarious }) => idVarious), countersIds: pointCounters.map(({ idVarious }) => idVarious) }
    })()
    const volumes = exploitationVolumes.filter((v) => v.idInstallation === idInstallation && v.askedYear === year)
    const askedVolume = volumes.reduce((acc, v) => acc + (v.askedVolume || 0), 0)
    const attributedVolume = volumes.reduce((acc, v) => acc + (v.attributedVolume || 0), 0)
    const authorizedVolume = volumes.reduce((acc, v) => acc + (v.authorizedVolume || 0), 0)
    const pointVolumes = {
        askedVolume,
        attributedVolume,
        authorizedVolume,
    }
    const materielsTypeForIndex = useApplicationSetting('materielsTypeForIndex', (setting) => setting ? setting.split(',').map((id) => Number(id)) : [])

    const chroniclesMat = exploitation.link_chronicles.filter(({ idMat }) => [...pumpsIds, ...countersIds].includes(idMat))
    const pointChronicles = chroniclesMat.filter(({ measureDate }) => new Date(measureDate).getFullYear() === year &&
        (!linkPoint.startDate || measureDate >= linkPoint.startDate) &&
        (!linkPoint.endDate || measureDate < linkPoint.endDate)
    )

    const pointChroniclePrevYear = orderBy(chroniclesMat.filter(({ measureDate }) => new Date(measureDate).getFullYear() === year - 1 &&
        (!linkPoint.startDate || measureDate >= linkPoint.startDate) &&
        (!linkPoint.endDate || measureDate < linkPoint.endDate)
    ), ['measureDate', 'endDate'], 'desc')[0]
    const chroniclesToShow = pointChroniclePrevYear ? [...pointChronicles, pointChroniclePrevYear] : [...pointChronicles]
    const groupedByMat = Object.values(groupBy(chroniclesToShow, 'idMat'))


    const fetchVolumes = () => {
        dispatch(AgriAction.calculPointVolumeReal(idInstallation, exploitation.idExploitation, moment(`31/01/${year}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${year}`, 'DD/MM/YYYY').valueOf())).then((res) => {
            if (!exploitation.idExploitation) {
                dispatch(AgriAction.fetchExploitation()).then(() => {
                    setRealConso(res?.total)
                    setDataLoaded(true)
                })
            } else {
                setRealConso(res?.total)
                setDataLoaded(true)
            }
            dispatch(ReferencialAction.fetchBookmarks())
        })
    }

    useEffect(() => {
        dispatch(CmsAction.fetchCmsByCategory(5))
        dispatch(ContactAction.fetchContacts()).then(() => setContactsLoaded(true))
        if (!exploitation.idExploitation) {
            dispatch(AgriAction.fetchExploitation()).then(() => fetchVolumes())
        } else {
            fetchVolumes()
        }
        window.scrollTo(0, 0)
    }, [])

/*
    const deleteBookmark = (fav) => {
        dispatch(ReferencialAction.deleteBookmark(fav)).then(() => {
            dispatch(ReferencialAction.fetchBookmarks())
        })
    }

    const createBookmark = (fav) => {
        dispatch(ReferencialAction.createBookmark(fav)).then(() => {
            dispatch(ReferencialAction.fetchBookmarks())
        })
    }
*/

    const getSampledTotal = (chronicles = []) => {
        if (!chronicles.length) return undefined
        if (chronicles.length === 1) return formatMilliers(chronicles[0].value)

        return formatMilliers(chronicles[0].value - chronicles[chronicles.length - 1].value)
    }


    const getColorRealConso = () => {
        if (!hasValue(authorizedVolume) || !hasValue(realConso)) {
            return 'black'
        }
        if (realConso > authorizedVolume) {
            return 'red'
        }
        if (((realConso / authorizedVolume) * 100) >= 80) {
            return 'orange'
        }
        return 'green'
    }

    const getChroniclesByType = (chroniclesByType, type, readingCoefficient = 1) => {
        if (chroniclesByType.length) {
            const orderedChronicles = orderBy(chroniclesByType, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
            const currentChronicles = orderedChronicles.filter((c) => moment(c.measureDate).year() >= year)
            const pastChronicles = orderedChronicles.filter((c) => moment(c.measureDate).year() < year)
            return {
                nbLines: currentChronicles.length,
                total: getSampledTotal(currentChronicles),
                content: (
                    <>
                        <GridItem item className='bold' xs={3} {...props} style={{ textAlign: 'start' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.startDate : i18n.statementDate}
                        </GridItem>
                        <GridItem item className='bold' xs={3} {...props} style={{ textAlign: 'start' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.endDate : ''}
                        </GridItem>
                        <GridItem item className='bold' xs={3} {...props} style={{ textAlign: 'end' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.estimateM3 : i18n.index}
                        </GridItem>
                        <GridItem item className='bold' xs={3} style={{ textAlign: 'end' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.accumulationM3 : i18n.evolutionM3}
                        </GridItem>
                        <Grid item xs={12} sx={{ borderBottom: `1px solid ${mainColor}`, margin: '10px 0' }} />
                        {currentChronicles.map((chronicle, i) => {
                            const valueEvol = i !== currentChronicles.length - 1 ? getEvolValue(type, currentChronicles, chronicle, i) : null
                            return (
                                <>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} {...props} style={{ textAlign: 'start' }}>
                                        {new Date(chronicle.measureDate).getFullYear() === year ? getMiniDateNoYear(chronicle.measureDate) : getMiniDate(chronicle.measureDate)}
                                    </GridItem>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} {...props} style={{ textAlign: 'start' }}>
                                        {chronicle.endDate ? (new Date(chronicle.endDate).getFullYear() === year ? getMiniDateNoYear(chronicle.endDate) : getMiniDate(chronicle.endDate)) : ''}
                                    </GridItem>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} {...props} style={{ textAlign: 'end' }}>
                                        {formatMilliers(chronicle.value) || 0}
                                    </GridItem>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} style={valueEvol < 0 ? { color: 'orange', textAlign: 'end' } : { textAlign: 'end' }}>
                                        {hasValue(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0))}` : ''}
                                    </GridItem>
                                </>
                            )
                        })}
                        {pastChronicles.length > 0 && <GridItem item xs={12}>...</GridItem>}
                        {pastChronicles.map((chronicle) => (
                            <>
                                <GridItem item xs={3} {...props} style={{ textAlign: 'start' }}>
                                    {getDate(chronicle.measureDate)}
                                </GridItem>
                                <GridItem item xs={3} {...props} style={{ textAlign: 'start' }}>
                                    {chronicle.endDate ? getDate(chronicle.endDate) : ''}
                                </GridItem>
                                <GridItem item xs={3} {...props} style={{ textAlign: 'end' }}>
                                    {`${formatMilliers(chronicle.value) || 0}${type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? 'm3' : ''}`}
                                </GridItem>
                                <GridItem item xs={3}>
                                    {moment(chronicle.measureDate).year() !== year && <Icon>access_time</Icon>}
                                </GridItem>
                            </>
                        ))}
                        <GridItem item xs={11}>
                            <Divider />
                        </GridItem>
                    </>
                )
            }
        }
        return { nbLines: 0, content: null }
    }

    const getChronicles = (groupedChronicles) => {
        return compact(groupedChronicles.map((chronicles) => {
            const variousMat = variousMateriels.find((mat) => mat.id === chronicles[0].idMat) || {}
            if (hasValue(variousMat.administrator) && variousMat.administrator !== exploitation.operatorCode) {
                return null
            }
            const indexChronicles = chronicles.filter((c) => c.measureType === CHRONICLES_CONSTANTS.TYPE_INDEX)
            const estimateChronicles = chronicles.filter((c) => c.measureType === CHRONICLES_CONSTANTS.TYPE_ESTIM)
            const readingCoefficient = variousMat?.counter?.readingCoefficient || 1
            const indexs = getChroniclesByType(indexChronicles, CHRONICLES_CONSTANTS.TYPE_INDEX, readingCoefficient)
            const estimates = getChroniclesByType(estimateChronicles, CHRONICLES_CONSTANTS.TYPE_ESTIM, variousMat)
            const isNotEstim = materielsTypeForIndex.includes(variousMat.materielType)
            return {
                nbLines: indexs.nbLines + estimates.nbLines,
                title: (
                    <Grid container flexWrap='nowrap' alignItems='center' justifyContent='space-between'>
                        <Grid container item alignItems='center'>
                            {variousMat.pump ? <PumpLogo style={{ paddingRight: 10 }}/> : <CounterLogo style={{ paddingRight: 10 }}/>} {variousMat.pump ? i18n.pump: i18n.counter} {variousMat.reference} {variousMat?.counter?.informative && ` (${i18n.informative})`}
                        </Grid>
                        <Grid item container alignItems='center' justifyContent='flex-end' sx={{ paddingRight: '3vw' }}>
                            {i18n.total} : {isNotEstim ? `${indexs.total ?? '-'} ${indexs.total ? i18n.m3 : ''}` : `${estimates.total ?? '-'} ${estimates.total ? i18n.m3 : ''}`}
                        </Grid>
                    </Grid>
                ),
                content: (
                    <Grid
                        item
                        container
                        direction='row'
                        justifyContent='center'
                    >
                        {indexs.content}
                        {estimates.content}
                    </Grid>
                )
            }
        }))
    }
    const chroniclesValues = getChronicles(groupedByMat)

    const getEstimConso = (groupedChronicles) => {
        return groupedChronicles.map((chroniques) => {
            return chroniques.filter((c) => c.measureType === 1 && moment(c.measureDate).year() >= year).reduce((acc, c) => acc + c.value, 0) || 0
        }).reduce((acc, c) => acc + c, 0)
    }
    const estimConso = groupedByMat.length ? getEstimConso(groupedByMat) : null

    const onChangeYear = (yearToFetch) => {
        dispatch(AgriAction.calculPointVolumeReal(idInstallation, exploitation.idExploitation, moment(`31/01/${yearToFetch}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${yearToFetch}`, 'DD/MM/YYYY').valueOf())).then((res) => {
            setRealConso(res?.total)
            setYear(yearToFetch)
        })
    }

    const getContent = () => {
        return (
            <>
                <Grid container item rowGap='16px' sx={{ backgroundColor: screenColor, borderRadius: '12px', padding: '4vh' }}>
                    <Grid
                        item
                        container
                        className='bold'
                        alignItems='center'
                        justifyContent='center'
                        sx={{
                            fontSize: '16px',
                            lineHeight: '24px'
                        }}
                    >
                        {i18n.campaign}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={{ borderBottom: `1px solid ${mainColor}` }}
                    >
                        <Grid item>
                            <TextButton
                            sx={{
                                margin: 0
                            }}
                            onClick={() => {
                                onChangeYear(year - 1)
                            }}
                            startIcon={<PlayArrow sx={{ rotate: '180deg' }} />}>
                                {year - 1}
                            </TextButton>
                        </Grid>
                        <Grid item>
                            <b style={{ fontSize: '32px', fontWeight: 400 }}>{year}</b>
                        </Grid>
                        <Grid item>
                            <TextButton
                                onClick={() => {
                                    onChangeYear(year + 1)
                                }}
                                className={year === new Date().getFullYear() ? 'hide' : ''}
                                endIcon={<PlayArrow />}
                            >
                                {year + 1}
                            </TextButton>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        sx={{ padding: '0 10 0 10', color: mainColor }}
                    >
                        <Grid
                            item
                            xs={12}
                            container
                            direction='column'
                            alignItems='center'
                        >
                            {/* <GridItem item xs={7} style={{ textAlign: 'start' }}>
                                {i18n.requestedVolume}
                            </GridItem>
                            <GridVolume item xs={5} className='textRight valueLabel'>
                                {hasValue(pointVolumes.askedVolume) ? `${formatMilliers(pointVolumes.askedVolume)} m3` : '-'}
                            </GridVolume>
                            <GridItem item xs={7} style={{ textAlign: 'start' }}>
                                {i18n.allocatedVolume}
                            </GridItem>
                            <GridVolume item xs={5} className='textRight valueLabel'>
                                {hasValue(pointVolumes.attributedVolume) ? `${formatMilliers(pointVolumes.attributedVolume)} m3` : '-'}
                            </GridVolume> */}
                            <Grid item container justifyContent='space-between' sx={{ padding: '15px 0' }}>
                                <Grid item>
                                    {i18n.authorizedVolume}
                                </Grid>
                                <Grid item>
                                    {hasValue(pointVolumes.authorizedVolume) ? `${formatMilliers(pointVolumes.authorizedVolume)} m3` : '-'}
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent='space-between' sx={{ padding: '15px 0', backgroundColor: veryLightColor }}>
                                <Grid item>
                                    {i18n.estimConsumption}
                                </Grid>
                                <Grid item>
                                    {hasValue(estimConso) ? `${formatMilliers(estimConso)} ${i18n.m3}` : '-'}
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent='space-between' sx={{ padding: '15px 0' }}>
                                <Grid item>
                                    {i18n.realConsumption}
                                </Grid>
                                <Grid item sx={{ color: getColorRealConso(pointVolumes.authorizedVolume, realConso) }}>
                                    {hasValue(realConso) ? `${formatMilliers(realConso)} m3` : '-'}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container >
                    {chroniclesValues.map((c) => (
                            <AccordeonDesktop key={c.title} title={c.title} item xs={12} sx={{ width: '100%' }}>
                                {c.content}
                            </AccordeonDesktop>
                    ))}
                </Grid>
            </>
        )
    }

    if (variousMatSituations.length && variousMateriels.length && dataLoaded && contactsLoaded && exploitation.idExploitation) {
        return (
            <Grid container alignItems='center' justifyContent='space-around' sx={{ padding: '8px 1.5rem' }}>
                {getContent(pumpsIds, countersIds)}
            </Grid>
        )
    }
    return <LoadingCard />
}

PointConsoDesktop.propTypes = {
    match: PropTypes.instanceOf(PropTypes.shape({})),
}

export default PointConsoDesktop